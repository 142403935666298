<template>
  <v-container>
    <v-row class="d-flex align-start">
      <v-col lg="9" class="my-5 pr-5">
        <h1 class="indigo--text text--lighten-1 text-center">¿QUÉ ES LA METAFÍSICA?</h1>
        <p class="text-center">Una espiritualidad dinámica</p>
        <div class="d-flex justify-center">
          <v-img
            max-width="70%"
            src="../../public/que_es_la_metafisica.jpg"
          ></v-img>
        </div>
        <p class="text-center font-weight-light">
          FERNANDO CASTRO | 20 DE JUNIO DE 2011
        </p>
        <p class="text-justify">
          La metafísica es la espiritualidad dinámica que está en uso en la
          Nueva Era de Acuarius, lo que en palabras sencillas se puede definir
          como una enseñanza que tiene fuerza y es activa, y que busca la
          realización del ser humano por medio de la práctica, más que por la
          simple creencia. No es estática ni apegada al dogma de una filosofía
          dirigida por la fe ciega, sino más bien se lleva por la aplicación de
          las leyes o principios que rigen el universo, y quienes la practican
          se vuelven totalmente proactivos.
        </p>
        <p class="text-justify">
          Se dice que la metafísica es “espiritualidad dinámica” porque libera a
          quienes la ponen en práctica de todos sus problemas; dándoles
          felicidad y conocimiento interior; otorgándoles la suprema capacidad
          de “Ser Luz para sí mismos” y que les permite dejar las dependencias
          religiosas como las autoridades psicológicas; enseña a conocer de
          forma diligente el poder que poseen los seres humanos, a concienciar,
          vivenciar y proyectar las cualidades Cristicas de: Buena Voluntad,
          Sabiduría, Amor, Belleza, Verdad, Salud, Paz y Perdón.
        </p>
        <p class="text-justify">
          La metafísica no es una religión aun cuando sus bases sean el
          cristianismo u otra tendencia religiosa, ya que rompe con los puntos
          de vista de la conformidad. Gran parte de su conocimiento está basado
          en las enseñanzas del Maestro Jesús que es enteramente una enseñanza
          espiritual dinámica, libre de teología y doctrinamiento.
        </p>
        <p class="text-justify">
          Esta enseñanza espiritual dinámica le entrega al ser humano la Llave
          Espiritual y Metafísica, que es “Una Base Espiritual” para saber como
          llevar su vida exitosamente.
        </p>
        <p class="text-justify">
          La metafísica nos enseña lo que es la esencia de Dios y lo que es
          nuestra propia esencia, nos declara la significación de la vida y de
          la muerte; nos enseña por qué los seres humanos cometemos errores y de
          dónde es que surgen los deseos que nos atrapan y que nos generan
          sufrimientos; nos facilita el conocimiento de por qué nos enfermamos,
          deterioramos, y nos avejentamos.
        </p>
        <h3 class="indigo--text text--lighten-1 text-center mb-2">PLANO DEL CONFLICTO</h3>
        <p class="text-justify">
          La metafísica nos da lo más importante para salir del “Plano del
          Conflicto” que es vivir en apuros, y en situaciones desgraciadas y de
          difícil salida. Enseñándonos a atraer salud, felicidad y prosperidad a
          nuestra vida y a la de los demás si en su libre albedrio así lo desean
          y escogen.
        </p>
        <h3 class="indigo--text text--lighten-1 text-center mb-2">TRES CUALIDADES</h3>
        <p class="text-justify">
          La metafísica de forma dinámica nos enseña que la Vida tiene tres
          cualidades ciertas y claras que son: SALUD, ALEGRÍA Y ENTUSIASMO y que
          la unión de estas tres forman lo que el Maestro Jesús nos señaló
          repetidamente en el “Sermón del Monte”, la BIENAVENTURANZA.
        </p>
        <p class="text-justify">
          En esta enseñanza que es el “Arte de Ser Feliz” aprendemos a eliminar
          los conceptos errados y absurdos que nos hemos hecho de Dios, como un
          Dios limitado y semejante al ser humano, que rige su creación
          despóticamente y con todos los defectos humanos.
        </p>
        <p class="text-justify">
          Nos libera de la esclavitud eterna del “Pecado Original”, del castigo
          infinito por nuestras trasgresiones y del tormento eterno mediante la
          enseñanza de que somos hijos de un Padre Amoroso y siempre presto a
          bendecirnos por medio de su Voluntad; que es el bien para nosotros y
          que se manifiesta a través de cada uno de los seres humanos por medio
          de su aspecto Vida, presente en EL LATIDO DEL CORAZÓN COMO PRIMERA
          PRUEBA DE VIDA.
        </p>
        <h3 class="indigo--text text--lighten-1 text-center mb-2">ACCESO DIRECTO A DIOS</h3>
        <p class="text-justify">
          La metafísica nos enseña que cada ser humano, por muy bajo que caiga,
          tiene acceso directo a un Dios Paternal, Todopoderoso, Sabio, Amoroso,
          Bello, Alegre, Pacifico y Misericordioso que es tardío en el castigo,
          quien los perdonara y les proporcionara Su propia fortaleza para que
          emprendan con éxito la “Divina Tarea” a descubrirse a sí mismo sin el
          temor de la condenación eterna.
        </p>
        <p class="text-justify">
          La metafísica nos enseña que no existe ningún fundamento de la
          enseñanza del Maestro Jesús sobre la cual establecer determinada forma
          de clero, jerarquía o grados espirituales separando a los seres
          humanos con sistemas ritualistas.
        </p>
        <p class="text-justify">
          La tonalidad del pensamiento y filosofía de la metafísica es
          totalmente libre, sin organización ni líderes; se llamen presidentes,
          mensajeros, iniciados o descendientes de alguien. Tampoco se necesita
          ninguna posición social, política, económica o título de nobleza para
          practicar su “arte de ser feliz”.
        </p>
      </v-col>
      <v-col lg="3" class="pl-2"><PanelDerecho /></v-col>
    </v-row>
  </v-container>
</template>
<script>
import PanelDerecho from "../components/Inicio/PanelDerecho";
export default {
  name: "Metafisica",
  components: {
    PanelDerecho,
  },
};
</script>